import _interopRequireDefault2 from "@babel/runtime/helpers/interopRequireDefault";
import _extends from "@babel/runtime/helpers/extends";
import _react2 from "react";
import _propTypes2 from "prop-types";
import _focusLock2 from "focus-lock";
import _util2 from "./util";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _extends2 = _interopRequireDefault(_extends);

var _react = _interopRequireDefault(_react2);

var _propTypes = _interopRequireDefault(_propTypes2);

var _focusLock = _focusLock2;
var _util = _util2;

var FreeFocusInside = function FreeFocusInside(_ref) {
  var children = _ref.children,
      className = _ref.className;
  return _react.default.createElement("div", (0, _extends2.default)({}, (0, _util.inlineProp)(_focusLock.constants.FOCUS_ALLOW, true), {
    className: className
  }), children);
};

FreeFocusInside.propTypes = {};
FreeFocusInside.defaultProps = {
  disabled: false,
  className: undefined
};
var _default = FreeFocusInside;
exports.default = _default;
export default exports;